_cruxUtils._cruxLocale = {
	"crm.criteria.secondaryfield.valid.check" : "Inserisci il valore corrispondente per {0}",
	"crm.label.field":"Campo",//no i18n
"crm.label.value":"Valore",//no i18n
	"sentiment.criteria.wrongcriteria":"Il valore del criterio non pu\u00f2 essere superiore a {0}",//no i18n
"crm.mb.field.common.splc":"I caratteri speciali non sono consentiti. Inserisci un valore valido.",//no i18n
	"crm.label.field.plural":"campi",//no i18n
	"crm.label.in.minutes":"{0} (in minuti)",//no i18n
	"crm.security.roles.list":"Elenco ruoli",//no i18n
"crm.security.roles.lookup.info":"Selezionare un ruolo dall\u2019elenco.",//no i18n
"crm.territory.addterritory":"Aggiungi territorio",//no i18n
"crm.title.edit.territory":"Modifica territorio",//no i18n
"crm.territory.title.assign.territories":"Assegna territori",//no i18n
	"crm.label.context.help":"Guida",//no i18n
	"crm.label.from":"Da",//no i18n
"crm.label.to":"A",//no i18n
	"workflow.option.webhookFailure.fromDate":"Data di inizio",//no i18n
"workflow.option.webhookFailure.toDate":"Data di conclusione",//no i18n
"crm.custom.field.less.than.equalto":"{0} deve essere inferiore o uguale a {1}.",//no i18n
	"crm.template.listview.search.no.results":"Nessun risultato trovato",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"La data di inizio non deve essere posteriore alla data di conclusione.",//no i18n
	"crm.label.tag.new":"Nuovo tag",//No I18n
	"crm.label.enter.tag":"Inserisci i tag",//No I18n
	"crux.comboBox.max.limit":"Impossibile selezionare pi\u00f9 di {0} {1}.",//NO I18n
	"Administrator":"Amministratore",//No I18n
	"Standard":"Standard",//No I18n
	"crm.button.add":"Aggiungi",//NO I18n
	"crm.label.users":"Utenti", //NO I18n
  "crm.workflow.alert.roles":"Ruoli", //NO I18n
  "crm.security.groups":"Gruppi", //NO I18n
	"crm.label.available" : "Disponibile", //NO I18n
	"crm.label.assign.manually" : "Assegna", //NO I18n
	"crm.globalsearch.option.all": "Tutto", //NO I18n
	"webform.status.Active":"Attivo", //NO I18n
	"Inactive":"Inattivo", //NO I18n
  "Confirmed":"Confermato", //NO I18n
  "crm.user.component.unconfirmed":"Non confermato",//no i18n
  "DeletedUser":"Eliminato", //NO I18n
  "crm.feed.group.admin":"Amministrazione", //NO I18n
  "crm.ln.lable.current":"Corrente", //NO I18n
	"crm.label.selected": "Selezionato",//NO I18n
	"crm.auditlog.user": "Utente", //NO I18n
	"cob.role": "Ruolo", //NO I18n
	"zoho.email": "E-mail", //NO I18n
	"Profile": "Profilo", //NO I18n
	"crm.security.group.users.empty": "Nessun utente trovato.", //NO I18n
	"crm.label.picklist.none": "Nessuno", //NO I18n
	"crm.usrpop.non.selected" : "Utenti selezionati",//NO I18n
	"crm.zti.label.user": "Nome utente", //NO I18n
	"crm.label.notSelected" : "Non selezionato",//NO I18n
	"AM" : "AM",//NO I18n
	"Call" : "Chiamata",//NO I18n
	"crm.phoneNo.Link.Title" : "Chiama tramite Skype",//NO I18n
	"crm.button.cancel" : "Annulla",//NO I18n
	"crm.button.save" : "Salva",//NO I18n
	"crm.no.data.found" : "Nessun dato trovato.",//NO I18n
	"crm.label.no.options.found" : "Nessuna opzione trovata.",//No I18n
	"crm.globalsearch.search.title" : "Cerca",//No I18n
	"None" : "Nessuno",//No I18n
	"crm.label.criteria.pattern" : "Modello criteri",//No I18n
	"crm.label.edit.criteria.pattern" : "Modifica schema",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "Le parentesi dello schema non corrispondono.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Parentesi tra gli operatori condizioni non valide.",//No I18n
	"crm.criteria.number.notmatch.check" : "Controllare lo schema su {0}.",//No I18n
	"criteria.error.alert.other.params" : "Contenuto non valido in questo schema.", //No I18n
	"crm.label.search.for.users": "Cerca utenti", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "Lo schema dei criteri non corrisponde alle condizioni scelte.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "criteria.error.alert.critnum.rowcount.mismatch", //No I18n
	"and" : "e", //No I18n
	"or" : "o", //No I18n
	"crm.label.or" : "O", //No I18n
	"crm.label.and" : "E", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Inserire un\u2019etichetta di campo valida nella riga {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "Specificare una condizione valida per {0}.", //No I18n
	"crm.field.valid.check" : "Inserire un {0} valido.", //No I18n
	"crm.custom.field.less.than.to" : "L\u2019intervallo <i>Da</i> non pu\u00f2 essere maggiore dell\u2019intervallo <i>A</i>.", //No I18n
	"crm.alert.label.savepattern" : "Salva lo schema prima di modificare i criteri.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "Non \u00e8 possibile aggiungere altri criteri.",//No I18n
	"is" : "\u00e9",//No I18n
	"isn\'t" : "non \u00e9",//No I18n
	"contains" : "contiene",//No I18n
	"doesn\'t contain" : "non contiene",//No I18n
	"starts with" : "inizia con",//No I18n
	"ends with" : "finisce con",//No I18n
	"is empty" : "vuota",//No I18n
	"is not empty" : "non \u00e8 vuoto",//No I18n
	"is before" : "\u00e9 prima",//No I18n
	"is after" : "\u00e9 dopo",//No I18n
	"between" : "compreso",//No I18n
	"not between" : "non compreso tra",//No I18n
	"Today" : "Oggi",//No I18n
	"Tommorow" : "Domani",//No I18n
	"Tommorow Onwards" : "Inizio previsto per domani",//No I18n
	"Yesterday" : "Ieri",//No I18n
	"Till Yesterday" : "Fino a ieri",//No I18n
	"Last Month" : "Mese scorso",//No I18n
	"Current Month" : "Mese corrente", //No I18n
	"Next Month" : "Mese successivo", //No I18n
	"Last Week" : "Settimana scorsa", //No I18n
	"Current Week" : "Settimana corrente", //No I18n
	"Next Week" : "Prossima settimana", //No I18n
	"Age in Days" : "Et\u00e0 in giorni", //No I18n
	"Due in Days" : "Dovuto in giorni", //No I18n
	"Scheduled" : "Pianificazione", //No I18n
	"Attended Dialled" : "Chiamato su Presente", //No I18n
	"Unattended Dialled" : "Chiamato su Non presente", //No I18n
	"Overdue" : "Scaduti", //No I18n
	"Cancelled" : "Annullato", //No I18n
	"Received" : "Ricevuto", //No I18n
	"Missed" : "Non risposta", //No I18n
	"crm.alert.character.not.allowed" : "{0} non consentito", //No I18n
	"crm.condition.in.last" : "negli ultimi", //No I18n
	"crm.zinvoice.dueIn" : "in scadenza a", //No I18n
	"on" : "Il",//No I18n
	"before" : "prima",//No I18n
	"crm.label.general.small.after" : "dopo",//No I18n
	"crm.thisweek" : "Questa settimana",//No I18n
	"crm.label.this.month" : "Questo mese",//No I18n
	"crm.thisyear" : "Quest\u2019anno",//No I18n
	"crm.source.user.and.system" : "Utente e sistema",//No I18n
	"crm.source.user.or.system" : "Utente o sistema",//No I18n
	"crm.label.system2" : "Sistema",//No I18n
	"crm.source.user.only" : "Solo per utente",//No I18n
	"crm.source.system.only" : "Solo per sistema",//No I18n
	"crm.condition.till.today" : "Fino ad oggi",//No I18n
	"game.month.left" : "1 mese",//No I18n
	"game.months.left" : "{0} mesi",//No I18n
	"crm.condition.last.30.days" : "negli ultimi 30 giorni",//No I18n
	"crm.condition.last.60.days" : "negli ultimi 60 giorni",//No I18n
	"crm.condition.last.90.days" : "negli ultimi 90 giorni",//No I18n
	"crm.label.filter.typehere" : "Digita qui", //No I18N
	"crm.filter.is.not" : "non \u00e8", //No I18n
	"crm.condition.until.now" : "Finora",//No I18n
	"crm.filter.email.isblocked" : "\u00e8 bloccata",//No I18n
	"crm.filter.email.isnotblocked" : "non \u00e8 bloccata",//No I18n
	"crm.label.no.results.match" : "Nessuna corrispondenza nei risultati",//No I18n
	"crm.label.select.user" : "Fai clic per selezionare gli utenti.", //No I18n
	"current.logged.in.user": "Utente connesso", //NO I18n
	"current.logged.in.user.definition": "L\u2019utente che avvia l\u2019azione di registrazione.", //NO i18n
	"crm.security.group": "Gruppo", //NO I18n
	"crm.security.role": "Ruolo", //NO I18n
	"Date" : "Data",//No I18n
	"crm.field.valid.decimal.check2" : "Le posizioni decimali per il campo <i>{0}</i> devono essere minori o uguali a {1}.",//No I18n
	"crm.field.empty.check" : "{0} non pu\u00f2 essere vuoto.",//No I18n
	"crm.label.add.note": "Aggiungi nota", //NO I18n
	"crm.label.simply.by": "da", //NO I18n
	"crm.general.addnote": "Aggiungi una nota", //NO I18n
	"crm.general.addtitle": "Aggiungi un titolo", //NO I18n
	"crm.label.attach.file": "Allega file", //NO I18N
	"crm.button.mass.delete": "Elimina", //NO I18N
	"crm.warning.delete.record": "Si desidera veramente spostare \u201c{0}\u201d nel Cestino?", //NO I18N
	"crm.label.yes": "S\u00ec", //NO I18N
	"crm.note.view.previous": "Visualizza note precedenti", //NO I18N
  "of": "di", //NO I18N
	"crm.label.notes": "Note", //NO I18N
	"crm.note.recent.first": "Primo recente", //NO I18N
	"crm.note.recent.last": "Ultimo recente", //NO I18N
	"crm.territory.label.only": "Solo {0}", //no i18n
	"crm.select" : "Seleziona",//No I18n
	"crm.button.apply.filter" : "Applica filtro",//No I18n
	"crm.alert.maximum.text.values.contains" : "Non \u00e8 possibile inserire pi\u00f9 di {0} valori per questo campo.",//No I18n
	"PM" : "PM",//No I18n
	"Jan" : "Gen",//No I18n
	"Feb" : "Feb",//No I18n
	"Mar" : "Mar",//No I18n
	"Apr" : "Apr",//No I18n
	"Jun" : "Giu",//No I18n
	"Jul" : "Lug",//No I18n
	"Aug" : "Ago",//No I18n
	"Sep" : "Set",//No I18n
	"Oct" : "Ott",//No I18n
	"Nov" : "Nov",//No I18n
	"Dec" : "Dic",//No I18n
	"crm.mb.newversion.msg4" : "OK, ricevuto!",//No I18n
	"crm.label.More" :"Altro", //no i18n

	"crm.label.unmapped.stages":"Inspiegato", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Mostra altro",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Mostra meno",//No I18n

	 	 //filter related keys-start
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.tooltip.convert":"PROBABILIT\u00c0",//no i18n
"crm.lead.prediction.tooltip.score":"PUNTEGGIO",//no i18n
"Planned":"Pianificato",//no i18n
"Invited":"Invitato",//no i18n
"Sent":"Spedita",//no i18n
"Received":"Ricevuto",//no i18n
"Opened":"Aperto",//no i18n
"Responded":"Risposto",//no i18n
"Bounced":"Respinto",//no i18n
"Opted\ Out":"Non accettato",//no i18n
"crm.filter.label.with.open":"Con {0} aperto",//no i18n
"crm.filter.label.without.open":"Senza aprire {0}",//no i18n
"crm.filter.label.without.any":"Senza qualsiasi {0}",//no i18n
"crm.filter.label.with.module":"Con {0}",//no i18n
"crm.filter.label.activity.due":"{0} in scadenza",//no i18n
"crm.filter.label.activity.done":"{0} Fatto",//no i18n
"Notes":"Note",//no i18n
"crm.filter.label.notes.added":"Note aggiunte",//no i18n
"crm.label.filter.email.status":"Stato ultima e-mail",//no i18n
"crm.label.filter.email.clicked":"cliccata",//no i18n
"crm.label.filter.email.responded":"con risposta", //no i18n
"crm.label.filter.email.info":"Filtra i record in base all\u2019ultimo stato delle tue e-mail inviate/ricevute.",//no i18n
"crm.filter.label.sent":"inviato",//no i18n
"crm.filter.label.not.sent":"non inviato",//no i18n
"crm.filter.label.opened":"aperto",//no i18n
"crm.filter.label.not.opened":"non aperto",//no i18n
"crm.filter.label.received":"ricevuto",//no i18n
"crm.filter.label.not.received":"non ricevuto",//no i18n
"crm.filter.label.bounced":"respinto",//no i18n
"crm.filter.label.opened.not.replied":"aperto e senza risposta", //no i18n
"crm.filter.label.any":"Nessuna delle precedenti",//no i18n
"crm.zia.config.potential.amount":"Importo {0}",//no i18n
"Quote\ Stage":"{0} Fase",//no i18n
"crm.module.owner":"{0} Proprietario",//no i18n
"Potential\ Closing\ Date":"Data di chiusura {0}",//no i18n
"crm.lead.prediction.likely.convert":"Probabile alla conversione",//no i18n
"crm.lead.prediction.convert.high":"Alta",//no i18n
"crm.lead.prediction.convert.medium":"Media",//no i18n
"crm.lead.prediction.convert.low":"Bassa",//no i18n
"crm.predictions.feature.label":"Previsione",//no i18n
"crm.intelligence.prediction.likelywin":"Probabile vincitore",//no i18n
"crm.intelligence.prediction.likelylose":"Probabile perdente",//no i18n
"crm.intelligence.prediction.halfchance":"Probabilit\u00e0 50:50",//no i18n
"crm.intelligence.prediction.score":"Punteggio di previsione",//no i18n
"crm.lead.prediction.recent.score":"Punteggio previsione recente",//no i18n
"crm.intelligence.prediction.lastconv":"Ultime 3 conversazioni",//no i18n
"crm.intelligence.prediction.recordsfocus":"Record su cui concentrarsi",//no i18n
"crm.intelligence.prediction.slowmoving":"Movimento lento",//no i18n
"crm.intelligence.prediction.trend.down":"Andamento negativo recente",//no i18n
"crm.label.touched.records":"Record toccati",//no i18n
"crm.label.untouched.records":"Record non toccati",//no i18n
"crm.label.record.action":"Registra azione",//no i18n
"workflow.rule.view.label.Modified":"Modificati",//no i18n
"crm.label.not.modified":"Non modificato",//no i18n
"crm.label.related.records.action":"Azione relativa ai record",//no i18n
"Done":"Fatto",//no i18n
"crm.label.not.done":"Non eseguito",//no i18n
"sentiment.model":"Valutazione e-mail",//no i18n
"sentiment.criteria.count":"Numero",//no i18n
"sentiment.criteria.percentage":"Percentuale",//no i18n
"sentiment.criteria.lastmail":"Per l\u2019ultima e-mail",//no i18n
"Chats":"Chat",//no i18n
"Attended":"Partecipato",//no i18n
"crm.lead.prediction.popup.text":"Quando la probabilit\u00e0 di convertire \u00e8 {0}, il punteggio di previsione dovrebbe essere tra {1}.",//no i18n
"crm.lead.prediction.popup.final":"Modificare il filtro di conseguenza e riprovare.",//no i18n
"crm.custom.field.less.than.to1":"L\u2019intervallo Da non pu\u00f2 essere maggiore dell\u2019intervallo A.",//no i18n
"Last\ Activity\ Date":"Data ultima attivit\u00e0",//no i18n
"crm.label.vendor.name":"Nome {0}",//no i18n
"hours":"ore",//no i18n
"days":"giorni",//no i18n
"weeks":"settimane",//no i18n
"months":"mesi",//no i18n
"years":"anni",//no i18n
"crm.label.general.small.after":"dopo",//no i18n
"Last\ Week":"Settimana scorsa",//no i18n
"Last\ Month":"Mese scorso",//no i18n
"crm.module.name":"Nome {0}",//no i18n
"Campaign":"Campagna",//no i18n
"Tasks":"Compiti",//no i18n
"Calls":"Chiamate",//no i18n
"Events":"Eventi",//no i18n
"sentiment.criteria.wrongcriteria":"Il valore del criterio non pu\u00f2 essere superiore a {0}",//no i18n
"crm.chosen.minimum.input.text":"Inserire {0} o pi\u00f9 caratteri",//no i18n
"crm.intelligence.prediction.trendup":"Andamento in aumento",//no i18n
"crm.intelligence.prediction.trenddown":"Andamento in diminuzione",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"Completato" ,//no i18n
"crm.label.success":"Operazione riuscita" ,//no i18n
"crm.label.Failure":"Errore" ,//no i18n
"Both":"Entrambi" ,//no i18n
"crm.condition.cannot.empty":"Il campo Condizione non pu\u00f2 essere vuoto.",//no i18n
"crm.condition.last.30.days":"negli ultimi 30 giorni",//no i18n
"crm.condition.last.60.days":"negli ultimi 60 giorni",//no i18n
"crm.condition.last.90.days":"negli ultimi 90 giorni",//no i18n
"crm.sentiment.Positive":"Positivo",//no i18n
"crm.sentiment.Negative":"Negativo",//no i18n
"sentiment.positiveandnegative":"Positivo e negativo",//no i18n
"sentiment.positiveornegative":"Positivo o negativo",//no i18n
"sentiment.positiveonly":"Solo positivo",//no i18n
"sentiment.negativeonly":"Solo negativo",//no i18n
"crm.sentiment.Neutral":"Neutro",//no i18n
"crm.filters.select.campaign.type":"Seleziona tipo {0}",//no i18n
"crm.filters.select.campaign.status":"Seleziona stato {0}",//no i18n
"campaign.Member" : "Membro",//no i18n
	"Service":"Servizio",//no i18n
"Activities":"Attivit\u00e0",//no i18n
"crm.livedesk.pot.nextdays":"Prossimi {0} giorni",//no i18n
"Today\ +\ Overdue":"Oggi + Arretrato",//no i18n
"crm.source.user.and.system":"Utente e sistema",//no i18n
"crm.source.user.or.system":"Utente o sistema",//no i18n
"User":"Utente",//no i18n
"crm.source.user.only":"Solo per utente",//no i18n
"crm.source.system.only":"Solo per sistema",//no i18n
"Scheduled":"Pianificazione",//no i18n
"Attended\ Dialled":"Chiamato su Presente",//no i18n
"Unattended\ Dialled":"Chiamato su Non presente",//no i18n
"Cancelled":"Annullato",//no i18n
"crm.filter.email.isblocked":"\u00e8 bloccata",//no i18n
"crm.filter.email.isnotblocked":"non \u00e8 bloccata",//no i18n
"condition.till.now":"Finora",//no i18n
"crm.recurring.no.months":"{0} mesi",//no i18n
"crm.lead.prediction.tooltip":"Probabile alla conversione - Intervallo di punteggio",//no i18n
"crm.website.activity":"Attivit\u00e0 sito Web",//no i18n
"crm.label.By":"Per",//no i18n
"crm.chosen.searching.text":"Ricerca in corso...",//no i18n
"crm.label.memberstatus.is":"e lo stato del membro \u00e8",//no i18n
"crm.events.duration":"Durata",//no i18n
"crm.title.clear.name":"Annulla",//no i18n
"crm.label.status.is":"e lo stato \u00e8",//no i18n
"zia.last3.help":"La conversazione include chiamate, attivit\u00e0, {0}, e-mail ricevute, note, visite, commenti social e richieste di supporto da Desk.",//no i18n
"crm.label.tag.related.to":"relativo a",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "Verr\u00e0 creato un nuovo {0}.",//No I18n
	"crm.krp.no.records.found" : "Nessun {0} trovato",//No I18n
	"crm.module.new" : "Nuovo {0}",//No I18n
	"crm.label.view" : "Visualizza",//No I18n
	"crm.nsocial.customers" : "Clienti",//No I18n
	"crm.nsocial.open.potential" : "{0} aperti",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Altri",//No i18n
	"crm.field.length.check" : "Il valore {0} supera la lunghezza consentita.", //No I18n
	"crm.lower.now":"adesso",//no i18n
	"crm.time.min.ago":"{0} minuti fa",//no i18n
	"crm.time.mins.ago":"{0} minuti fa",//no i18n
	"crm.time.hr.ago":"{0} ora fa",//no i18n
	"crm.time.hrs.ago":"{0} ore fa", //no i18n
	"AllUsers": "Tutti gli utenti" ,//no i18n
	"crm.label.search":"Cerca",//no i18n
	"crm.api.filterby":"Filtra per",//no i18n
	"crm.customview.nofields.found":"--Nessun campo corrispondente--",//no i18n
	"crm.setup.system.ziarecommendation":"Raccomandazione",//no i18n
	"crm.filter.label.all.products":"Tutti i {0}",//no i18n
	"crm.filter.label.select.products":"{0} selezionato/i",//no i18n
	"crm.reviewprocess.smart.filter":"Stato record del processo di revisione",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Scegliere un valore.",//no i18n
	"crm.segmentation.segment.score":"Punteggio segmento",//no i18n
	"crm.filter.label.in":"basato su",//no i18n
	"crm.filter.label.and.purchase.in":"e con probabilit\u00e0 di acquisto in",//no i18n
	"crm.filter.label.last.purchased":"e di recente acquisizione",//no i18n
	"crm.filter.label.a.day":"un giorno",//no i18n
	"crm.filter.label.a.week":"una settimana",//no i18n
	"crm.filter.label.a.month":"un mese",//no i18n
	"crm.cal.custom":"Personalizzato",//no i18n
	"crm.mb.field.common.empt":"Il valore non pu\u00f2 essere vuoto.",//no i18n
	"crm.chosen.error.loading.text":"Oops, non siamo stati in grado di caricare i risultati",//no i18n
	"crm.filter.label.firstbuy":"\u00c8 la prima volta",//no i18n
	"crm.filter.label.cwbab":"Dipendente",//no i18n
	"crm.filter.label.fbt":"Pacchetto",//no i18n
	"crm.filter.label.rebuy":"Ripeti",//no i18n
	"crm.filter.label.nextbuy":"Sequenza",//no i18n
	"crm.mxnlookup.select" : "Assegna {0}",//No I18n
	"crm.lookup.chooserecord":"Scegli {0}",//no i18n
	"crm.record.selected":"{0} selezionati",//no i18n
	"crm.module.empty.message" : "{0} - nessun elemento trovato",//No I18n
	"crm.mxnlookup.selected" : "{0} assegnati",//No I18n
	"crm.security.error" : "Non si dispone delle autorizzazioni sufficienti per eseguire questa operazione. Contattare l\u2019amministratore.", //No I18n
	"crm.label.creator.noPermission" : "Autorizzazione negata", //No I18n
	"crm.segmentation.recency" : "Recente", //No I18n
	"crm.segmentation.frequency" : "Frequenza", //No I18n
	"crm.segmentation.monetary" : "Monetario", //No I18n
	"crm.smartfilter.related.module.msg" : "Non \u00e8 possibile selezionare pi\u00f9 di tre moduli correlati." , //No I18n
	"crm.smartfilter.related.module.msg1" : "(Es.: E-mail, Attivit\u00e0, Note" , //No I18n
	"crm.smartfilter.related.module.msg2" : "Il campo Durata non pu\u00f2 essere vuoto", //No I18n
	"crm.label.timeZone": "Fuso orario", //NO I18n
	"crm.label.insufficient.privileges": "Privilegi insufficienti per eseguire questa operazione. Contattare l\u2019amministratore.", //NO I18n
	"crm.filter.header.secton.system": "Filtri definiti dal sistema", //NO I18N
	"crm.filter.header.secton.fields": "Filtra per campi", //NO I18N
	"crm.createfield.calcinfo.new" : "Questo campo funge da calcolatore per qualsiasi espressione immessa.</br> <b>Ad es. 20+20</b> produrr\u00e0 <b>40</b> automaticamente",//No i18n
	"crm.lable.read.only" : "Campo di sola lettura",//No i18n
	"crm.column.sort.asc" : "Asc",//No I18n
	"crm.column.sort.desc" : "Desc.",//No i18n
	"crm.column.unsort" : "Differenzia",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Condividi con il cliente", //NO I18N
	"crm.label.edited": "Edited", //NO I18N
	"crm.label.edited.on": "Edited on", //NO I18N
	"crm.message.limit.exceed": "Sono consentiti solo {0} caratteri per {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Condiviso con il cliente", //NO I18N
	"crm.button.ok" : "OK", //NO I18N
	"crm.role.already.selected" : "Questo ruolo \u00e8 gi\u00e0 selezionato", //no i18n
	"crm.user.deleted": "L'UTENTE \u00c8 STATO ELIMINATO",  //NO I18N
	"crm.account.closed": "QUESTO ACCOUNT \u00c8 STATO CHIUSO",  //NO I18N
	"crm.start.chat": "Avvia chat",  //NO I18N
	"crm.start.call": "Avvia una chiamata",  //NO I18N
	"crm.recipient.invalid.email" : "Trovate e-mail non valide", //NO I18N
	"crm.recipient.add.recipient" : "Aggiungi ulteriore destinatario", //NO I18N
	"crm.start.video.call": "Avvia una videochiamata",  //NO I18N //ignorei18n_start

	"Score":"Punteggio",
	"Positive Score":"Punteggio positivo",
	"Negative Score":"Punteggio negativo",
	"Touch Point Score":"Punteggio punto di contatto",
	"Positive Touch Point Score":"Punteggio punto di contatto positivo",
	"Negative Touch Point Score":"Punteggio punto di contatto negativo",
	"crm.label.scoring.rule":"Regole di assegnazione punteggio",
	"crm.label.type.minutes": "Digita qui in pochi minuti", //NO I18N

	"is\ OPEN":"\u00e8 APERTO",//no i18n
	"is\ WON":"\u00e8 VINTO",//no i18n
	"is\ LOST":"\u00e8 PERSO",//no i18n
	"crm.potential.all.open":"Tutte le fasi aperte",//no i18n
	"crm.potential.all.won":"Tutte le fasi vinte chiuse",//no i18n

	"crm.campaign.member.status" : "Stato del membro",//no i18n
	"crm.dashboard.select.type" : "Seleziona {0}",//no i18n
	"crm.campaign.service.status":"Stato del servizio",//no i18n

	"crm.label.addColumn":"Aggiungi colonna",//no i18n
	"crm.button.clear.filter":"Chiudi filtro",//no i18n
	"crm.button.show.filter":"Mostra filtro",//no i18n
	"crm.las.error.user.maxlimit":"Puoi selezionare un massimo di 20 utenti.",//no i18n
	"crm.las.error.picklist.maxlimit":"Puoi selezionare un massimo di 20 opzioni.",//no i18n

	"crm.potential.all.lost":"Tutte le fasi perse chiuse", //no i18n
	"crm.fileuploader.message.responseerror": "Caricamento non riuscito", //NO I18N
	"crm.storage.create.error":"Nessun nuovo record pu\u00f2 essere creato poich\u00e9 hai raggiunto il limite massimo del tuo spazio di archiviazione.",//no i18n
	"crm.storage.create.error.client":"Nessun nuovo record pu\u00f2 essere creato poich\u00e9 il tuo amministratore ha raggiunto il limite massimo dello spazio di archiviazione. Contatta {0} per risolvere il problema.",//no i18n
	"crm.storage.avail.info":"({0} rimanenti di {1})",//no i18n
	"crm.storage.error.key.manage":"Gestisci il tuo spazio di archiviazione",//no i18n
	"Records":"Record",//no i18n
	"crm.workflow.alert.additional.recipients" : "Destinatari aggiuntivi", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Usa le virgole per separare pi\u00f9 indirizzi e-mail.", //NO I18N
	"crm.related.contact.account" : "{0} relativo a {1}",//No I18n
	"crm.allcontact.show" : "Tutti i {0}",//No I18n
	"crm.button.mass.show" : "Mostra",//No I18n
	"crm.msg.custom.view.not.replied" : "Messaggi senza risposta", //NO I18N
	"crm.msg.custom.view.replied" : "Messaggio con risposta",//NO I18N
	"crm.workflow.select.recipients" : "Destinatari", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Selezionare almeno un profilo.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Spiacenti, ma non \u00e8 possibile rimuovere un profilo predefinito.",//NO I18N
	"crm.inv.label.add.emails" : "Aggiungi e-mail", //NO I18N
	"crm.prediction.analytics.filter.year":"L\u2019anno scorso",//no i18n
	"Previous\ FY":"Precedente FY",//no i18n
	"Current\ FY":"Corrente FY",//no i18n
	"Next\ FY":"Prossimo FY",//no i18n
	"Current\ FQ":"Corrente FQ",//no i18n
	"Next\ FQ":"Prossimo FQ",//no i18n
	"Previous\ FQ":"Precedente FQ",//no i18n
	"crm.picklist.sample.text":"Testo di esempio",//no i18n
	"crm.more.colors":"Altri colori",//no i18n
	"crm.button.back.alone":"Indietro",//no i18n
	"crm.field.label.email":"E-mail",//no i18n
"crm.zia.nba.feature.label":"Migliore azione successiva",//no i18n
"Meeting":"Incontro", //no i18n
"Tomorrow":"Domani",//no i18n
"crm.gdpr.notavailable.field":"Non disponibile",//no i18n
	"crm.setup.system.ziasimilarity":"Raccomandazione di somiglianza",//no i18n
	"crm.gdpr.notavailable.field":"Non disponibile",//no i18n
	"crm.filter.label.all.products":"Tutti i {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Names", // NO I18N
	"abm.abm.segment.name": "ABM Segment Name", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentation Techniques", // NO I18N
	"abm.filter.by.abm.fields": "Filter By ABM Fields", // NO I18N
	"abm.rfm": "RFM", // NO I18N
	"abm.firmographics": "Firmographics", // NO I18N
	"crux.custom.field.greater.than.equalto":"{0} deve essere maggiore o uguale a {1}.",
	"crux.users.selected.plural" : "{0} utenti selezionati.",
	"crux.user.selected.singular" :"{0} utente selezionato.",
	"crux.criteria.empty.secondaryfield.module" : "Non è stato trovato nessun campo {0} nel modulo {1}",
	"crux.criteria.empty.secondaryfield" : "Nessun altro campo {0} disponibile per ilo confronto; inserisci un valore per il confronto.",
	"crux.logged.in.role.definition" : "Il ruolo utente che avvia le azioni del record",
	"zia.similarity.smartfilter.score":"Punteggio di somiglianza",//no i18n
	"zia.similarity.smartfilter.records":"Somiglianza {0} di",//no i18n
	"zia.similarity.smartfilter.records.search":"Visualizza somiglianza {0} di",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Non puoi selezionare pi\u00f9 di {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} deve essere maggiore o uguale a {1}.",
	"crux.users.selected.plural" : "{0} utenti selezionati.",
	"crux.user.selected.singular" :"{0} utente selezionato.",
	"crux.criteria.empty.secondaryfield.module" : "Non è stato trovato nessun campo {0} nel modulo {1}",
	"crux.criteria.empty.secondaryfield" : "Nessun altro campo {0} disponibile per ilo confronto; inserisci un valore per il confronto.",
	"crux.logged.in.role.definition" : "Il ruolo utente che avvia le azioni del record",
	"crux.max.limit.unselect" : "Non puoi rimuovere la selezione a più di {0} {1}.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d è già selezionato" //NO I18N
}
